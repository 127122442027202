import React from 'react';
import { navigate, withPrefix } from 'gatsby';

import languages from '../data/languages';

class RedirectIndex extends React.PureComponent {
  constructor() {
    super();

    // Skip build, Browsers only
    if (typeof window !== 'undefined') {
      const homeUrl = withPrefix(`/${languages.defaultLangKey}/`);
      navigate(homeUrl);
    }
  }

  render() {
    return (<div />);
  }
}

export default RedirectIndex;
